import {
  createApp
} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import contentful from './plugins/contentful'
import moment from './plugins/moment'
import * as Sentry from "@sentry/vue";
import {
  Integrations
} from "@sentry/tracing";

import '@/assets/global.scss'

const app = createApp(App)

if (!window.webpackHotUpdate) {
  Sentry.init({
    app,
    dsn: "https://f06558ca3868463886f8abc848f227e4@o954433.ingest.sentry.io/5903495",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "contentful-dashboard.pages.dev", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

app.use(store).use(router).use(contentful).use(moment).mount('#app')