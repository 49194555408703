import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Index.vue'),
    meta: {
      requiresAuth: true,
      title: "Aurora dashboard - Главная"
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    meta: {
      title: "Aurora dashboard - Вход"
    }
  },
  {
    path: '/dashboard',
    name: 'Select',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Select.vue'),
    meta: {
      requiresAuth: true,
      title: "Aurora dashboard - Выбор пространства"
    }
  },
  {
    path: '/dashboard/:space/:env/:locale/:type?',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      title: "Aurora dashboard - Управление"
    }
  },
  {
    path: '/dashboard/:space/:env/:locale/:type/new',
    name: 'Create',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Create.vue'),
    meta: {
      requiresAuth: true,
      title: "Aurora dashboard - Создание контента"
    }
  },
  {
    path: '/dashboard/:space/:env/:locale/:type/edit/:content',
    name: 'Edit',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Edit.vue'),
    meta: {
      requiresAuth: true,
      title: "Aurora dashboard - Изменение контента"
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Aurora dashboard"
  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (!store.state.token) {
      next({ name: 'Login' });
      return;
    }
  }
  if (to.fullPath.endsWith("/") && to.fullPath !== "/") {
    next({ path: to.fullPath.substring(0, to.fullPath.length - 1) });
    return;
  }
  next();
})

export default router
