<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">Aurora dashboard</router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item" v-for="link in links" :key="link.key">
            <router-link
              :to="link.link"
              :class="[
                'nav-link',
                { disabled: !$store.state.token, active: link.active() }
              ]"
              >{{ link.title }}</router-link
            >
          </li>
        </ul>
        <button
          @click="logout"
          v-if="$store.state.token"
          class="btn btn-outline-danger justify-self-end"
          type="submit"
        >
          Выйти
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          link: "/",
          title: "Главная",
          key: "index",
          active: () => this.$route.path === "/"
        },
        {
          link: "/dashboard",
          title: "Панель управления",
          key: "dashboard",
          active: () => this.$route.path.startsWith("/dashboard")
        }
      ]
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("set_token", "");
      this.$router.push("/login");
    }
  }
};
</script>

<style scoped>
.justify-self-end {
  margin-left: auto;
}
</style>
