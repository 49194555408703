import { createStore } from 'vuex'

export default createStore({
  state: {
    token: localStorage.getItem('contentful_token'),
    user: JSON.parse(localStorage.getItem('contentful_user')),
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_USER(state, user) {
      state.user = user
    }
  },
  actions: {
    set_token(context, token) {
      localStorage.setItem('contentful_token', token)
      context.commit('SET_TOKEN', token)
    },
    set_user(context, user) {
      localStorage.setItem('contentful_user', JSON.stringify(user))
      context.commit('SET_USER', user)
    }
  },
})
